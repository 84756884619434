import React from "react";
import { render } from "react-dom";


const DisciplineGraph = () => {

  const rawHTML = `
  <style>
  #disgraphcontainer {
      height: 700px;
      min-width: 310px;
      max-width: 1100px;
      margin: 0 auto;
  }
  
  .loading {
      margin-top: 10em;
      text-align: center;
      color: gray;
  }
  
  </style>
  <div id="disgraphcontainer" style="height: 450px;min-width: 310px; max-width: 1100px; margin: 0 auto;">
    <div class="loading">
        <i class="icon-spinner icon-spin icon-large"></i>
        Loading...
    </div>
  </div>
 
  `;

return (
  <div className="container disgraphcontainer" dangerouslySetInnerHTML={{ __html: rawHTML }} />
  );

};

export default DisciplineGraph;
