import React from "react";
import { render } from "react-dom";
// import { useScienceCommunityBottomContent } from "../../../../hooks/useScienceCommunity/useScienceCommunityBottomContent";
// import Spacer from "../../../Spacer/Spacer";

const MemberMap = () => {
  // const { heading, copy, showSection } = useScienceCommunityBottomContent();

  const rawHTML = `

  <style>
  #mapcontainer {
      height: 700px;
      min-width: 310px;
      max-width: 1100px;
      margin: 0 auto;
  }
  
  .loading {
      margin-top: 10em;
      text-align: center;
      color: gray;
  }
  
  </style>
  
  <div id="mapcontainer" class="">
    <div class="loading">
        <i class="icon-spinner icon-spin icon-large"></i>
        Loading...
    </div>
  </div>
 
  `;

return (
  <div className="container mapcontainer" dangerouslySetInnerHTML={{ __html: rawHTML }} />
  );

};

export default MemberMap;
