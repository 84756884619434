import React from "react";
import { useScienceCommunityDisciplines } from "../../../../hooks/useScienceCommunity/useScienceCommunityDisciplines";
import Spacer from "../../../Spacer/Spacer";

const DisciplinesSection = () => {
  const { heading, copy, showSection } = useScienceCommunityDisciplines();

  return (heading || copy) && showSection ? (
    <div className="container">
      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-8">
          <Spacer size={20} mobileSize={20} />
          {heading && <h2>{heading}</h2>}
          {copy && (
            <>
              <Spacer size={20} mobileSize={10} />
              <div
                className="science-community__content content-wrapper custom-list"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: copy,
                }}
              />
            </>
          )}
          <Spacer size={20} mobileSize={20} />

        </div>
        <div className="col-md-2" />
      </div>
    </div>
  ) : null;
};

export default DisciplinesSection;
