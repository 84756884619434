import { useStaticQuery, graphql } from "gatsby";

const useScienceCommunitySubmitAProposal = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Science Community" } }) {
        scienceCommunity {
          submitAProposalSection {
            heading
            tabContent {
              tabItem {
                title
                tabContent {
                  copy
                  heading
                  subheading
                  link {
                    target
                    title
                    url
                  }
                  showStep
                }
              }
            }
            showSection
          }
        }
      }
    }
  `);

  return data.wpPage.scienceCommunity.submitAProposalSection;
};

export default useScienceCommunitySubmitAProposal;
