import { useStaticQuery, graphql } from "gatsby";
export const useScienceCommunityHeader = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Science Community" } }) {
        id
        scienceCommunity {
          headingSection {
            heading
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                }
              }
              altText
            }
          }
        }
      }
    }
  `);
  return data.wpPage.scienceCommunity.headingSection;
};
