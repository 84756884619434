import { useStaticQuery, graphql } from "gatsby";

export const useScienceCommunityCommunityFaces = () => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    allWpCommunityface {
      edges {
        node {
          databaseId
          isrCommunityFaces {
            bio
            country
            firstName
            institution
            interestarea
            lastName
            name
            suffix
            title
            quote
            picture {
                altText
                localFile {
                  url
                  uid
                  publicURL
                  extension
                  name
                  dir
                  relativeDirectory
                  relativePath
                }
              }
          }
        }
      }
    }
  }
  
  `);

  return data;
};
