import React, { useState } from "react";
// import uniqueId from "lodash/uniqueId";

import "./CommunityFaces.scss";
import { useScienceCommunityCommunityFaces } from "../../../../hooks/useScienceCommunity/useScienceCommunityCommunityFaces";
// import BioModalCommunityFaces from "../../../BioModal/BioModalCommunityFaces";
// import BioCardFaces from "../../../BioCard/BioCardFaces";
import Spacer from "../../../Spacer/Spacer";
// import IsrButton from "../../../IsrButton/IsrButton";

function shuffleface(sourceArray) {
  for (let i = 0; i < sourceArray.length - 1; i++) {
    const j = i + Math.floor(Math.random() * (sourceArray.length - i));

    const temp = sourceArray[j];
    sourceArray[j] = sourceArray[i];
    sourceArray[i] = temp;
  }
  return sourceArray;
}

const CommunityFaces = () => {
  const mycommunityfaces = useScienceCommunityCommunityFaces();


  const [bioModalOpen, setModalOpen] = useState(false);
  const [selectedMemberToRead, setSelectedMemberToRead] = useState(null);

  const handleReadBioClick = async (member) => {
    await setSelectedMemberToRead(member);
    setModalOpen(true);
  };

  // console.log(mycommunityfaces);
  let randomfaces = shuffleface(mycommunityfaces.allWpCommunityface.edges);
  var size = 3;
  var threerandomfaces = randomfaces.slice(0, size);

  let faces = `
  <style>
.communitymodal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.communitymodal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.communityclose {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  border:none;
}

.communityclose:hover,
.communityclose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.facesbio p {font-family: helveticaneue;
          font-size: 1.5rem !important;
          line-height: 1.5;
          margin-left:10%;
          margin-right:10%;
          text-align:left;
}
</style>

  `;

  faces += `<div class="container" style="display:block;"><div class="row membersContainer" style="--bs-gutter-x: 1.5rem;  --bs-gutter-y: 0;  display: flex; flex-wrap: wrap;
    ;text-align:center;justify-content: center;
    width: 100%;">`;

  faces += threerandomfaces.map(
    (face) => `
    <div class="col-12 col-md-6 col-lg-4 col-xxl-3 bioCard -center" key="face${
      face.node.databaseId
    }" style="text-align:center;max-width:300px;">
        <div style="width:205px;position: relative; width: 150px; height: 150px; overflow: hidden; border-radius: 50%;">
        <img src="${(face.node.isrCommunityFaces.picture && face.node.isrCommunityFaces.picture.localFile && face.node.isrCommunityFaces.picture.localFile.url) ? `${face.node.isrCommunityFaces.picture.localFile.url}` :
          ""
        }" style="width: 100%; height: auto; "  />
        </div>
        <div style="margin-top:10px;margin-bottom:15px;">
        <div style="font-weight: 700; margin: 2rem 0 0.5rem;font-size: 1.8rem;padding-bottom:10px;">${
          face.node.isrCommunityFaces.name ? `${face.node.isrCommunityFaces.name}<br />`
          : ""
        }</div>
        <div class="memberBio" style="margin-bottom:10px;">
        ${
          face.node.isrCommunityFaces.title
            ? `${face.node.isrCommunityFaces.title}<br />`
            : ""
        }
        ${
          face.node.isrCommunityFaces.institution
            ? `${face.node.isrCommunityFaces.institution}<br />`
            : ""
        }
        ${
          face.node.isrCommunityFaces.interestarea
            ? `${face.node.isrCommunityFaces.interestarea}<br />`
            : ""
        }

        </div>
        <button type="button" id="communityface${
            face.node.databaseId
          }" class="communityfaces-button isr-button arrow -md -with-children4 align-bottom" onclick="communitymodal(${
            face.node.databaseId
          });" ><span class="arrow-icon"><div class="right-arrow"></div></span>Read Bio</button>
  
        </div>
    </div>

    <div class="communitymodal communityfacemodal" style="display:none;"  tabindex="-1" role="dialog" id="modal-communityface${
        face.node.databaseId
      }">
    <div class="communitymodal-dialog" role="document">
      <div class="communitymodal-content">
       
            <button type="button" class="communityclosebutton communityclose" data-dismiss="modal" aria-label="Close">
            <span class="communityclosebutton" aria-hidden="true">&times;</span>
          </button>
        
        <div class="communitymodal-body" style="text-align:center;display:block;">
         
          <div style="text-align:center; width:205px;position: relative; width: 150px; height: 150px; overflow: hidden; border-radius: 50%;margin-left:auto;margin-right:auto;">
          <img src="${(face.node.isrCommunityFaces.picture && face.node.isrCommunityFaces.picture.localFile && face.node.isrCommunityFaces.picture.localFile.url) ? `${face.node.isrCommunityFaces.picture.localFile.url}` :
            ""
          }" style="width: 100%; height: auto; "  />
          </div>
 
          <div style="font-weight: 700; margin: 2rem 0 0.5rem;font-size: 1.8rem;padding-bottom:10px;">${
            face.node.isrCommunityFaces.name ? `${face.node.isrCommunityFaces.name}<br />`
            : ""
          }</div>
          <div class="memberBio" style="margin-bottom:10px;"><p>
          ${
            face.node.isrCommunityFaces.title
              ? `${face.node.isrCommunityFaces.title}<br />`
              : ""
          }
          ${
            face.node.isrCommunityFaces.institution
              ? `${face.node.isrCommunityFaces.institution}<br />`
              : ""
          }
          ${
            face.node.isrCommunityFaces.interestarea
              ? `${face.node.isrCommunityFaces.interestarea}<br />`
              : ""
          }</p>
          </div>          
          <blockquote style="font-family: helveticaneue;
          font-size: 1.8rem !important;
          line-height: 1.5;font-weight:600;color:#aaa;">"
          ${
            face.node.isrCommunityFaces.quote
              ? `${face.node.isrCommunityFaces.quote}<br />`
              : ""
          }
          "
          </blockquote>
          <div class="facesbio" style="font-family: helveticaneue;
          font-size: 1.5rem !important;
          line-height: 1.5;">${
            face.node.isrCommunityFaces.bio
              ? `${face.node.isrCommunityFaces.bio}<br />`
              : ""
          }</div>
          
        </div>
        <div class="communitymodal-footer">         
          <button type="button" class="btn btn-secondary communityclosebutton isr-button" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>


    ` 
   );
  faces = `${faces}</div></div>`;

  return (
      <>
    <div style={{ textAlign: "center" }}>
      <div
        className="facescontainer"
        dangerouslySetInnerHTML={{ __html: faces }}
      />
    </div>
    </>
  );
};

export default CommunityFaces;
