import React from "react";
import { useScienceCommunityTopContent } from "../../../../hooks/useScienceCommunity/useScienceCommunityTopContent";
import Spacer from "../../../Spacer/Spacer";

const TopContentSection = () => {
  const { copy, showSection } = useScienceCommunityTopContent();
  return copy && showSection ? (
    <div className="container">
      <Spacer size={50} mobileSize={30} />
      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-8">
          <div
            className="science-community__content content-wrapper"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
          <Spacer size={20} mobileSize={20} />
          <hr size={3} className="divider grey" />
        </div>
        <div className="col-md-2" />
      </div>
    </div>
  ) : null;
};

export default TopContentSection;
