import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import ScienceCommunity from "../components/ScienceCommunity/ScienceCommunity";
import Footer from "../components/Footer/Footer";

const ScienceCommunityPage = () => {
  return (
    <Layout>
      <Header />
      <ScienceCommunity />
      <Footer />
    </Layout>
  );
};

export default ScienceCommunityPage;
