import React from "react";
import { useScienceCommunityHeader } from "../../hooks/useScienceCommunity/useScienceCommunityHeader";
import Jumbotron from "../Jumbotron/Jumbotron";

import TopContentSection from "./components/TopContentSection/TopContentSection";
import GlobalCommunitySection from "./components/GlobalCommunitySection/GlobalCommunitySection";

import BottomContentSection from "./components/BottomContentSection/BottomContentSection";
import CommunityRoleSection from "./components/CommunityRoleSection/CommunityRoleSection";
import MemberMap from "./components/MemberMap/MemberMap";
import DisciplinesSection from "./components/DisciplinesSection/DisciplinesSection";
import DisciplineGraph from "./components/MemberMap/DisciplineGraph";
import CommunityFacesContent from "./components/CommunityFaces/CommunityFacesContent";
import CommunityFaces from "./components/CommunityFaces/CommunityFaces";

import SubmitProposalSection from "./components/SubmitProposalSection/SubmitProposalSection";
import { useScienceCommunityPromoSlots } from "../../hooks/useScienceCommunity/useScienceCommunityPromoSlots";
import PromoSlotsSection from "../PromoSlotsSection/PromoSlotsSection";
import "./ScienceCommunity.scss";

const ScienceCommunity = () => {
  const { heading, backgroundImage } = useScienceCommunityHeader();
  const promoSlotsData = useScienceCommunityPromoSlots();
  return (
    <div className="science-community">
      <Jumbotron media={backgroundImage} title={heading} />
      <TopContentSection />
      <GlobalCommunitySection />
      <MemberMap />
      <DisciplinesSection />
      <DisciplineGraph />
      <CommunityFacesContent />
      <CommunityFaces />
      <CommunityRoleSection />
      <BottomContentSection />
      <SubmitProposalSection />
      <PromoSlotsSection data={promoSlotsData} />
    </div>
  );
};
export default ScienceCommunity;
