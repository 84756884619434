import { useStaticQuery, graphql } from "gatsby";

export const useScienceCommunityTopContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Science Community" } }) {
        scienceCommunity {
          topContentSection {
            copy
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.scienceCommunity.topContentSection;
};
